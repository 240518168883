import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import myImage from '../assets/poker-hands.png';

const RulesPopup = () => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };

    return (
        <div>
            <button onClick={togglePopup} className="button">
                RULES
            </button>
            <Popup
                open={isOpen}
                onClose={() => setIsOpen(false)}
                modal
                closeOnDocumentClick
                contentStyle={{ width: 'auto', padding: '20px' }}
            >
                <div className="popup">
                    <h2>Poker Rules (Texas Hold'em)</h2>
                    <h3>The Game Setup</h3>
                    <ul>
                        <li>
                            <strong>Players:</strong> 2-5 players.
                        </li>
                        <li>
                            <strong>Cards:</strong> Each player receives two
                            hole cards face-down.
                        </li>
                        <li>
                            <strong>Community Cards:</strong> In stages, five
                            cards are dealt face-up in the center for everyone
                            to use.
                        </li>
                    </ul>
                    <h3>The Game Flow</h3>
                    <ol>
                        <li>
                            <strong>Preflop:</strong> Players bet on their
                            initial two cards.
                        </li>
                        <li>
                            <strong>Flop:</strong> Three community cards are
                            dealt face-up. Players can bet, raise, call, or fold
                            (see in betting actions below).
                        </li>
                        <li>
                            <strong>Turn:</strong> The fourth community card is
                            dealt. One more betting round.
                        </li>
                        <li>
                            <strong>River:</strong> The fifth community card is
                            dealt. Final betting round.
                        </li>
                        <li>
                            <strong>Showdown:</strong> If more than one player
                            remains, they reveal their hands. The best hand wins
                            the pot.
                        </li>
                    </ol>
                    <h3>Hand Rankings</h3>
                    <ul>
                        <li>
                            Royal Flush: Ace, King, Queen, Jack, and 10 of the
                            same suit.
                        </li>
                        <li>
                            Straight Flush: Five consecutive cards of the same
                            suit.
                        </li>
                        <li>Four of a Kind: Four cards of the same rank.</li>
                        <li>
                            Full House: Three cards of one rank and two cards of
                            another.
                        </li>
                        <li>
                            Flush: Five cards of the same suit, but not
                            consecutive.
                        </li>
                        <li>Straight: Five consecutive cards of any suit.</li>
                        <li>Three of a Kind: Three cards of the same rank.</li>
                        <li>Two Pair: Two pairs of different ranks.</li>
                        <li>One Pair: Two cards of the same rank.</li>
                        <li>
                            High Card: The highest card in your hand if no other
                            combinations are made.
                        </li>
                    </ul>

                    <img
                        src={myImage}
                        alt="Poker Game"
                        style={{
                            width: '100%',
                            maxWidth: '300px',
                            marginTop: '20px',
                        }}
                    />

                    <h3>Betting Actions</h3>
                    <ul>
                        <li>
                            <strong>Bet:</strong> Places a bet.
                        </li>
                        <li>
                            <strong>Raise:</strong> Increases the current bet.
                        </li>
                        <li>
                            <strong>Call:</strong> Matches the current bet.
                        </li>
                        <li><strong>Check:</strong> Passes the action to the next player without betting, available when no bet has been made.</li>
                        <li>
                            <strong>Fold:</strong> Gives up their hand and loses
                            their bet.
                        </li>
                    </ul>
                    <p>
                        <strong>Note:</strong> The goal is to make the best
                        five-card hand using your two hole cards and the five
                        community cards.
                    </p>

                    <button onClick={togglePopup}>Close</button>
                </div>
            </Popup>
        </div>
    );
};

export default RulesPopup;
