import React from 'react';
import './Chip.css';

import blueChipImage from '../../assets/chips/red-chip.png';
import blackChipImage from '../../assets/chips/black-chip.png';
import greenChipImage from '../../assets/chips/green-chip.png';
import redChipImage from '../../assets/chips/red-chip.png';
import yellowChipImage from '../../assets/chips/yellow-chip.png';
import purpleChipImage from '../../assets/chips/purple-chip.png';
import cyanChipImage from '../../assets/chips/cyan-chip.png';

const Chip = ({ value, color, position }) => {
    let chipImage;

    switch (color) {
        case 'blue':
            chipImage = blueChipImage;
            break;
        case 'black':
            chipImage = blackChipImage;
            break;
        case 'green':
            chipImage = greenChipImage;
            break;
        case 'red':
            chipImage = redChipImage;
            break;
        case 'yellow':
            chipImage = yellowChipImage;
            break;
        case 'purple':
            chipImage = purpleChipImage;
            break;
        case 'white':
        default:
            chipImage = cyanChipImage;
            break;
    }

    return (
        <div
            className="chip"
            style={{
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`,
                backgroundImage: `url(${chipImage})`,
                backgroundSize: 'cover',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
            }}
        >
            <span className="chip-value">{value}</span>
        </div>
    );
};

export default Chip;
