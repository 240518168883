import React from 'react';
import './Card.css';

const Card = ({ value, suit, isFaceDown }) => {
    const cardName = `${suit}${value}`;
    const cardSrc = `/img/cards/${cardName}.svg`;
    const cardBack = `/img/cards/back.svg`;

    return (
        <div className="card">
            <img
                // width={1000}
                height={65}
                src={isFaceDown ? cardBack : cardSrc}
                alt={isFaceDown ? "Card Back" : `${value} of ${suit}`}
                className="card-image"
            />
        </div>
    );
};

export default Card;
