import React from 'react';
import Chip from '../Chip/Chip';

const ChipStack = ({ amount }) => {
    const chipValues = [500, 100, 50, 25, 10, 5, 1];
    const colors = {
        500: 'purple',
        100: 'black',
        50: 'blue',
        25: 'green',
        10: 'red',
        5: 'yellow',
        1: 'white',
    };
    let remaining = amount;
    const chips = [];

    chipValues.forEach((value) => {
        const count = Math.floor(remaining / value);
        remaining %= value;
        for (let i = 0; i < count; i++) {
            chips.push({ value, color: colors[value] });
        }
    });

    return (
        <div className="chip-stack">
            {chips.map((chip, index) => (
                <Chip
                    key={index}
                    value={chip.value}
                    color={chip.color}
                    position={{ x: 0, y: -index * 3 }}
                />
            ))}
        </div>
    );
};

export default ChipStack;
