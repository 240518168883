import React, { useState } from 'react';
import './RaiseSlider.css';
import chipBalanceImage from '../../assets/chips/chip-balance.svg';

const RaiseSlider = ({ minBet, maxBet, balance, onConfirm }) => {
    const [currentBet, setCurrentBet] = useState(minBet);

    const handleSliderChange = (e) => {
        setCurrentBet(Number(e.target.value));
    };

    const handleFractionClick = (fraction) => {
        setCurrentBet(Math.min(Math.floor(balance * fraction), maxBet));
    };

    return (
        <div className="raise-container">
            {/* Close Button */}
            <button className="close-button" onClick={() => onConfirm(null)}>
                X
            </button>

            {/* Slider and Manual Adjustment Section */}
            <div className="slider-adjustment">
                <div className="slider-section">
                    <input
                        type="range"
                        min={minBet}
                        max={maxBet}
                        value={currentBet}
                        onChange={handleSliderChange}
                        className="raise-slider"
                    />
                    {/* Buttons Below Slider */}
                    <div className="fraction-buttons">
                        <button onClick={() => setCurrentBet(minBet)}>
                            MIN
                        </button>
                        <button onClick={() => handleFractionClick(0.25)}>
                            1/4
                        </button>
                        <button onClick={() => handleFractionClick(0.5)}>
                            1/2
                        </button>
                        <button onClick={() => handleFractionClick(0.75)}>
                            3/4
                        </button>
                        <button onClick={() => setCurrentBet(maxBet)}>
                            ALL IN
                        </button>
                    </div>
                </div>

                <div className="manual-adjust">
                    <div className="current-bet">
                        <img
                            src={chipBalanceImage}
                            alt="Chips"
                            className="chips-icon"
                        />
                        {currentBet}
                    </div>
                </div>

                <button
                    className="confirm-button"
                    onClick={() => onConfirm(currentBet)}
                >
                    CONFIRM
                </button>
            </div>
        </div>
    );
};
export default RaiseSlider;
